<!--
Display gtfs route information 
-->

<template>
  <v-container>
    <v-card-title>
      <route-chip :route="content" class="mr-4" />
      <div v-html="content.route_long_name" />
      <v-spacer />
      <route-type-icon large :route-type="content.route_type" tooltip />
    </v-card-title>
    <v-tabs-items v-model="tab">
      <v-tab-item value="statistics">
        <v-card-actions>
          <v-row>
            <v-col cols="5">
              <info-day-type-selector />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>
          <values-table
            :data="statistics"
            :vertical-headers="route_stats_headers"
            :export-filename="export_filename"
            label-format="network.stats_labels"
            v-bind="value_table_props"
          />
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="variants">
        <route-variants :content="content" />
      </v-tab-item>
      <v-tab-item value="cover">
        <route-cover :content="content" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import RouteVariants from "./route_variants.vue";
import RouteCover from "./route_cover.vue";
import NetworkInfoMixin from "./network_info_mixin.vue";

const route_stats_headers = [
  { value: "is_bidirectional", format: "boolean" },
  { value: "num_trips" },
  { value: "start_time" },
  { value: "end_time" },
  { value: "mean_trip_distance", format: "", unit: "km" },
  { value: "mean_trip_duration", format: item => (item.value * 60).toFixed(1), unit: "min" },
  { value: "min_headway", format: "", unit: "min" },
  { value: "max_headway", format: "", unit: "min" },
  { value: "service_distance", format: "", unit: "km" },
  { value: "service_duration", format: "", unit: "hours" },
  { value: "service_speed", format: "", unit: "km_per_hour" }
];

export default Vue.component("route-info", {
  components: { RouteVariants, RouteCover },
  mixins: [NetworkInfoMixin],

  data() {
    return {
      route_stats_headers
    };
  },

  computed: {
    statistics() {
      return this.getMatchingItem(this.content.statistics, {
        date: this.infoDayType.gtfs_date
      });
    },
    export_filename() {
      return `route_${this.content.route_short_name}_stats_${this.infoDayType.value}.xls`;
    }
  },

  methods: {}
});
</script>
