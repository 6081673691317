<!--
  Values table with a function triggered to get items.

  Emits "update:data" event on successful data fetch.
-->

<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col>
          <slot name="prepend" :trigger-data-fetch="triggerDataFetch" :global-loading="globalLoading"> </slot>
        </v-col>
        <v-col cols="2" v-if="computeButton">
          <v-btn class="mt-6" color="primary" :disabled="globalLoading" @click="triggerDataFetch">
            {{ $t("basic_dialogs.validate") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="justify-center mb-2">
      <values-table :data="selectedData" :vertical-headers="verticalHeaders" :loading="globalLoading" v-bind="$attrs" />
    </v-card-actions>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.component("fetch-values-table", {
  inheritAttrs: false,
  props: {
    // function called to get the statistics data (all content types)
    dataGetter: {
      type: Function,
      required: true
    },
    verticalHeaders: Array,
    // display a "compute" button to manually trigger stats evaluation
    computeButton: Boolean,
    // update this value to trigger data fetch (when computeButton is false)
    computeKey: Number,
    // loading indication from parent component
    loading: Boolean,
    dataAccessor: Function
  },
  mounted() {
    this._triggerDataFetch(true);
  },
  data: function () {
    return {
      // values-table data
      data: null,
      // indicates if data fetching is ongoing
      data_loading: false,
      // id of the current data call
      id_call: 0
    };
  },
  watch: {
    computeKey() {
      if (!this.computeButton) {
        this.triggerDataFetch();
      }
    }
  },

  computed: {
    selectedData() {
      if (this.dataAccessor) {
        return this.dataAccessor(this.data);
      } else {
        return this.data;
      }
    },
    globalLoading() {
      return this.data_loading || this.loading;
    }
  },
  methods: {
    triggerDataFetch() {
      this._triggerDataFetch(false);
    },
    _triggerDataFetch(mount = false) {
      // start fetch state
      this.id_call += 1;
      let call_when_fetching = this.id_call;
      this.data_loading = true;

      // fetch data using the provided dataGetter
      this.dataGetter(mount)
        .then(result => {
          if (this.id_call == call_when_fetching) {
            this.$emit("update:data", result);
            this.data = result;
          } else {
            console.log("Call has been overriden, but there shouldn't be concurrent calls");
          }
        })
        .catch(e => {
          alert({
            message: `An error occurred while fetching transport line data: ${e}`,
            type: "error"
          });
        })
        .finally(() => {
          this.data_loading = false;
        });
    }
  }
});
</script>
