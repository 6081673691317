<!--
Display gtfs route information 
-->

<template>
  <v-container>
    <v-card-title>
      <div v-html="currentNetworkView.name" />
    </v-card-title>
    <v-tabs-items v-model="tab">
      <v-tab-item value="statistics">
        <v-card-actions>
          <v-row>
            <v-col cols="5">
              <info-day-type-selector />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>
          <values-table
            :data="statistics"
            :vertical-headers="gtfs_stats_headers"
            :export-filename="export_filename"
            label-format="network.stats_labels"
            v-bind="value_table_props"
          />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import NetworkInfoMixin from "./network_info_mixin.vue";

const gtfs_stats_headers = [
  { value: "num_routes" },
  { value: "num_trips" },
  { value: "num_stops" },
  { value: "service_distance", format: "", unit: "km" },
  { value: "service_duration", format: "", unit: "hours" },
  { value: "service_speed", format: "", unit: "km_per_hour" },
  { value: "num_estimated_vehicles" }
];

export default Vue.component("gtfs-info", {
  mixins: [NetworkInfoMixin],
  data() {
    return {
      gtfs_stats_headers
    };
  },

  computed: {
    statistics() {
      return this.getMatchingItem(this.content.statistics, {
        date: this.infoDayType.gtfs_date
      });

      // return stop_stats_headers.map(header => {
      //   return {
      //     value: stats[header.value],
      //     text: this.$t(`statistics.network.${header.value}`)
      //   };
      // });
    },
    export_filename() {
      return `gtfs_stats_${this.infoDayType.value}.xls`;
    }
  },

  methods: {}
});
</script>
