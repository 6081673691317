<template>
  <fetch-values-table
    :card-title="selected_variant"
    :vertical-headers="vertical_headers"
    :data-getter="dataGetter"
    :loading="loading"
    export_filename="pt_line_osm"
    width="350px"
    :compute-key="computeKey"
    :data-accessor="dataAccessor"
    :items-per-page="-1"
    hide-default-footer
    @update:data="dataUpdateHandler"
  >
    <template #prepend="{ globalLoading }">
      <v-card-text>
        <v-select
          label="Mission"
          :items="variant_choices"
          v-model="selected_variant"
          :disabled="globalLoading"
          no-data-text="Aucune donnée trouvée pour cette ligne"
        />
      </v-card-text>
    </template>
  </fetch-values-table>
</template>

<script lang="ts">
import Vue from "vue";
import { formatTableContent } from "@/capucine_utils";
import * as turf from "@turf/turf";
import { ALL_ROUTES } from "@/capucine_utils";

const vertical_headers = [
  {
    value: "distance",
    text: "Longueur (m)"
  },
  {
    value: "agg_psv",
    text: "% voie réservée bus",
    format: "100%"
  },
  {
    value: "agg_cycleway_on_busway",
    text: "% piste cyclable sur voie réservée bus",
    format: "100%"
  },
  {
    value: "agg_traffic_signals",
    text: "Nombre de feux de signalisation"
  },
  {
    value: "agg_give_way",
    text: "Nombre de cédez-le-passage"
  },
  {
    value: "agg_roudabout",
    text: "Nombre de rond-point"
  },
  {
    value: "agg_stop",
    text: "Nombre de stop"
  },
  {
    value: "agg_without_traffic_signals_crossing",
    text: "Nombre passages piétons (hors feux de signalisation)"
  },
  {
    value: "agg_highway_motorway",
    text: "% réseau autoroutier",
    format: "100%"
  },
  {
    value: "agg_highway_primary",
    text: "% routes principales",
    format: "100%"
  },
  {
    value: "agg_highway_secondary",
    text: "% routes secondaires",
    format: "100%"
  },
  {
    value: "agg_highway_other",
    text: "% autres routes",
    format: "100%"
  },
  {
    value: "agg_lanes_only1",
    text: "% une seule voie de circulation",
    format: "100%"
  },
  {
    value: "agg_maxspeed30",
    text: "% vitesse limitée à 30km/h",
    format: "100%"
  }
];

export default Vue.component("pt-line-osm", {
  props: ["lineId", "lineGeojson", "loading"],
  data: function () {
    return {
      computeKey: 0,
      data: {},
      vertical_headers,
      variant_choices: [],
      selected_variant: null
    };
  },
  watch: {
    lineId: {
      handler(value) {
        this.computeKey += 1;
      }
    }
  },
  methods: {
    async dataGetter(_mount) {
      if (this.lineId == ALL_ROUTES) {
        return {};
      }

      let bbox = turf.bbox(this.lineGeojson);
      let result = await this.$whale.transportLineOsm(bbox, this.lineId);
      let data = {};
      let properties;
      let title;
      for (let i = 0; i < result.features.length; i++) {
        properties = result.features[i].properties;
        title = properties.direction;
        data[title] = properties;
      }
      return data;
    },
    async getLineInfo() {
      if (this.lineId == ALL_ROUTES) {
        return {};
      }

      let bbox = turf.bbox(this.lineGeojson);
      let result = await this.$whale.transportLineOsm(bbox, this.lineId);

      let features = {};
      let properties;
      let title;
      for (let i = 0; i < result.features.length; i++) {
        properties = result.features[i].properties;
        title = properties.direction;
        let items = [];
        for (let key in properties) {
          items.push({ label: key, value: properties[key] });
        }
        features[title] = items;
      }

      return features;
    },
    dataUpdateHandler(data) {
      this.data = data;
      this.variant_choices = Object.keys(this.data);
      this.selected_variant = this.variant_choices[0];
    },
    dataAccessor(data) {
      if (!data) {
        return undefined;
      }
      if (this.selected_variant) {
        return data[this.selected_variant];
      } else {
        return undefined;
      }
    }
  }
});
</script>
