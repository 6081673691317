<!--
This components displays a { key: value } object
in the form of a two columns table.
For each row, display options can be provided via the 
vertical-headers prop, for instance number formatting
or adding a chip.
-->

<template>
  <kite-table
    :headers="headers"
    :items="items"
    :item-formatter="itemFormatter"
    v-bind="$attrs"
    header-path-prefix="basic_headers"
  />
</template>

<script lang="ts">
import Vue from "vue";
import { formatTableContent } from "@/capucine_utils";

export default Vue.component("values-table", {
  props: {
    /**
     * Object containing the { key: value } pairs to display
     **/
    data: {
      type: Object
    },
    /**
     * Array containing items of the form
     * { text, value, format?, color?, unit? }
     **/
    verticalHeaders: {
      type: Array
    },
    /**
     * i18n path prefix or format function for labels
     **/
    labelFormat: {
      type: [String, Function]
    },
    // label sorting function
    sort: {
      type: Function
    }
  },

  data() {
    return {
      headers: [
        { text: "label", value: "label", sortable: false },
        { text: "value", value: "value", align: "right", sortable: false, width: "30%" }
      ]
    };
  },

  computed: {
    /**
     * evaluate a list of table items from the provided data
     * and vertical headers.
     *
     * One row per vertical header. Remove rows when data is missing.
     *
     * If no vertical headers are provided, display the data key/value pairs.
     * */
    items() {
      // return empty array when data is missing
      if (!this.data) {
        return [];
      }

      // default vertical headers are the data keys
      let verticalHeaders =
        this.verticalHeaders ||
        Object.keys(this.data).map(key => {
          return { value: key };
        });

      // build an item array with items of the form
      // { value, label, format?, color?, .. }
      let items = verticalHeaders.map(vheader => {
        return {
          // add vertical header info
          ...vheader,
          // get value from the data object
          value: this.data[vheader.value],
          // get the label from the header, fallback to key
          label: vheader.text || vheader.value
        };
      });

      // remove items where data is missing
      items = items.filter(item => item.value !== undefined);

      // sort items
      if (this.sort) {
        items = items.sort(this.sort);
      }

      return items;
    }
  },

  methods: {
    itemFormatter(header, item) {
      if (header.value == "label") {
        return this.labelFormatter(header, item);
      } else if (header.value == "value") {
        return this.valueFormatter(header, item);
      } else {
        throw new Error("Item formatting error");
      }
    },
    valueFormatter(header, item) {
      let format = item.format;
      if (format == undefined) {
        return item.value;
      } else if (typeof format == "function") {
        return format(item);
      } else {
        return formatTableContent(item.value, format);
      }
    },
    labelFormatter(header, item) {
      let unit;
      if (item.unit) {
        unit = ` (${this.$t("units." + item.unit)})`;
      } else {
        unit = "";
      }

      let format = this.labelFormat;
      if (format == undefined) {
        return item.label + unit;
      } else if (typeof format == "function") {
        return format(header, item);
      } else if (typeof format == "string") {
        return this.$t(`${format}.${item.label}`) + unit;
      }
    }
  }
});
</script>
