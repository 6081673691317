<!--
Display gtfs route information 
-->

<template>
  <v-chip
    v-bind="$attrs"
    style="font-weight: bold"
    :color="`#${routeColor}`"
    :text-color="`#${routeTextColor}`"
    v-on="$listeners"
  >
    {{ routeText }}
  </v-chip>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("route-chip", {
  components: {},
  props: {
    route: { required: true },
    textAccessor: {
      default: "route_short_name"
    },
    colorAccessor: {
      default: "route_color"
    },
    textColorAccessor: {
      default: "route_text_color"
    }
  },

  data() {
    return {};
  },

  computed: {
    routeText() {
      return this.accessRouteData(this.route, this.textAccessor);
    },
    routeColor() {
      return this.accessRouteData(this.route, this.colorAccessor);
    },
    routeTextColor() {
      return this.accessRouteData(this.route, this.textColorAccessor);
    }
  },

  methods: {
    accessRouteData(route, accessor) {
      let result;
      if (typeof accessor == "function") {
        result = accessor(route);
      } else if (typeof accessor == "string") {
        result = route[accessor];
      } else {
        result = undefined;
      }
      return result;
    }
  }
});
</script>
