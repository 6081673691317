<!--
Display gtfs route information 
-->

<template>
  <v-container>
    <v-card-title>
      <div v-html="content.stop_name" />
      <v-spacer />
      <v-chip-group>
        <route-chip
          v-for="route in passingRoutes"
          :key="route.route_id"
          :route="route"
          @click="setInfoDialogContent(route)"
        />
      </v-chip-group>
    </v-card-title>
    <v-tabs-items v-model="tab">
      <v-tab-item value="statistics">
        <v-card-actions>
          <v-row>
            <v-col cols="5">
              <info-day-type-selector />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>
          <values-table
            :data="statistics"
            :vertical-headers="stop_stats_headers"
            :export-filename="export_filename"
            label-format="network.stats_labels"
            v-bind="value_table_props"
          />
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="draw_pt_line"> TODO </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import NetworkInfoMixin from "./network_info_mixin.vue";

const stop_stats_headers = [
  { value: "num_trips" },
  { value: "start_time" },
  { value: "end_time" },
  { value: "min_headway", format: "", unit: "min" },
  { value: "max_headway", format: "", unit: "min" },
  { value: "mean_headway", format: "", unit: "min" }
];

export default Vue.component("stop-info", {
  mixins: [NetworkInfoMixin],
  data() {
    return {
      stop_stats_headers
    };
  },

  computed: {
    passingRoutes() {
      return this.currentNetworkView.routes.filter(route => {
        return this.content.passing_routes.includes(route.route_id);
      });
    },
    statistics() {
      return this.getMatchingItem(this.content.statistics, {
        date: this.infoDayType.gtfs_date
      });
    },
    export_filename() {
      return `stop_${this.content.stop_id}_stats_${this.infoDayType.value}.xls`;
    }
  }
});
</script>
