<!--
Dialog containing info on the currently selected network item.
-->

<template>
  <kite-tabs-dialog
    :dialog="dialog"
    :tabs="tabs[infoDialogContentType]"
    :title="$t('network.info_dialog.title')"
    @closeDialog="closeDialog"
  >
    <template v-slot="{ tab }">
      <component :is="info_component" :content="infoDialogContent" :tab="tab" />
    </template>
  </kite-tabs-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import GtfsInfo from "./gtfs_info.vue";
import RouteInfo from "./route_info.vue";
import StopInfo from "./stop_info.vue";
import KiteTabsDialog from "@/components/kite/base/kite_tabs_dialog.vue";

export default Vue.component("network-info-dialog", {
  components: { KiteTabsDialog, GtfsInfo, RouteInfo, StopInfo },

  data() {
    return {
      tabs: {
        gtfs: [{ key: "statistics", text: "network.tabs.statistics.title" }],
        route: [
          { key: "statistics", text: "network.tabs.statistics.title" },
          { key: "variants", text: "network.tabs.variants.title" },
          { key: "cover", text: "network.tabs.cover.title" }
        ],
        stop: [{ key: "statistics", text: "network.tabs.statistics.title" }]
      }
    };
  },

  computed: {
    ...mapState("network", ["infoDialogContent"]),
    ...mapGetters("network", ["infoDialogContentType"]),
    dialog() {
      if (this.infoDialogContent) {
        return true;
      } else {
        return false;
      }
    },
    info_component() {
      if (this.infoDialogContentType) {
        return `${this.infoDialogContentType}-info`;
      } else {
        return undefined;
      }
    }
  },

  methods: {
    ...mapActions("network", ["setInfoDialogContent"]),
    closeDialog() {
      this.setInfoDialogContent(null);
    }
  }
});
</script>
