<!--
  Table containing transport line statistics

  Emits "update:data" event on data update.
  Emits "update:buffer" event on buffer update.
-->

<template>
  <fetch-values-table
    :card-title="$t('network.draw_pt_line.data.desc', { buffer: buffer_size })"
    export_filename="pt_line_info"
    :labelFormat="formatText"
    :data-getter="dataGetter"
    :data-accessor="dataAccessor"
    hide-default-footer
    width="300px"
    :sort="getSort"
    @update:data="signalDataUpdate"
    :compute-key="computeKey"
    v-bind="$attrs"
  >
    <template #prepend="{ triggerDataFetch, globalLoading }">
      <v-card-text>
        <v-row align="center">
          <v-col cols="5">
            <v-select v-model="selected_content" label="Donnée affichée" :items="content_items"> </v-select>
          </v-col>
          <v-col>
            <v-slider
              class="mt-4"
              :label="'Distance (m)'"
              v-model="buffer_size"
              @change="
                triggerDataFetch();
                signalBufferUpdate($event);
              "
              min="100"
              max="1000"
              step="50"
              ticks
              thumb-label="always"
              :thumb-size="25"
              :readonly="globalLoading"
            />
          </v-col> </v-row
      ></v-card-text>
    </template>
  </fetch-values-table>
</template>

<script lang="ts">
import Vue from "vue";

import { mapState, mapActions } from "vuex";

const vertical_headers = "?";

export default Vue.component("pt-line-stats", {
  props: {
    // function called to get the transport line data (all content types)
    // function(lineGeojson, bufferSize, mount) -> stats data
    lineGeojsonData: Function,
    // geometry of the studied transport line (geojson)
    lineGeojson: {
      type: Object
    },
    // available stats content types
    contentKeys: {
      type: Array,
      default: () => {
        return ["poptot", "comp", "stat", "sch", "itinerary"];
      }
    }
  },
  data: function () {
    return {
      // type of statistics selected for display
      selected_content: null,
      // buffer area around geometry (in meters)
      buffer_size: 300,
      // key used to trigger data update (when computeButton=false)
      computeKey: 0
    };
  },
  mounted() {
    this.selected_content = this.content_items[0].value;
  },
  watch: {
    lineGeojson(value) {
      this.computeKey += 1;
    }
  },
  computed: {
    content_items() {
      return this.contentKeys.map(key => {
        return { value: key, text: this.$t("network.draw_pt_line.data." + key + ".label") };
      });
    },
    getSort() {
      if (this.selected_content == "comp") {
        return (a, b) => {
          if (a.label == "total") {
            return -1;
          } else if (b.label == "total") {
            return 1;
          }
          a = parseInt(a.label.split("_")[0]);
          b = parseInt(b.label.split("_")[0]);
          return a < b ? 1 : -1;
        };
      } else {
        return undefined;
      }
    }
  },
  methods: {
    dataGetter(mount) {
      if (this.lineGeojson.features.length == 0) {
        return;
      }
      let lineGeojsonData = this.lineGeojsonData || this.defaultLineGeojsonData;
      return lineGeojsonData(this.lineGeojson, this.buffer_size, mount);
    },
    defaultLineGeojsonData(lineGeojson, bufferSize, _mount) {
      return this.$whale.transportLinePopulation(lineGeojson, bufferSize);
    },
    dataAccessor(data) {
      if (!data) {
        return {};
      }
      let content_data = data[this.selected_content];
      if (content_data) {
        return Object.fromEntries(content_data.map(item => [item.label, item.value]));
      } else {
        return {};
      }
    },
    formatText(header, item) {
      return this.$t("network.draw_pt_line.data." + this.selected_content + "." + item.label);
    },
    signalBufferUpdate(value) {
      this.$emit("update:buffer", value);
    },
    signalDataUpdate(value) {
      this.$emit("update:data", value);
    }
  }
});
</script>
