import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./plugins/lang";
import KiteDialog from "./components/kite/base/kite_dialog.vue";
import KiteCardTitle from "./components/base/kite_card_title.vue";
import KiteTable from "./components/base/kite_table.vue";
import ValuesTable from "./components/base/values_table.vue";
import FetchValuesTable from "./components/base/fetch_values_table.vue";
import KiteTableAction from "./components/kite/base/kite_table_action.vue";
import KiteDeleteButton from "@/components/projects/button_delete.vue";
import KiteActionButton from "@/components/projects/button_action.vue";
import KiteEditText from "./components/kite/base/kite_edit_text.vue";
import JsonExcel from "vue-json-excel";

// Import the plugin here
import { WhalePlugin } from "./api";
import "@mdi/font/css/materialdesignicons.css";

// import global components
Vue.component("kite-dialog", KiteDialog);
Vue.component("kite-card-title", KiteCardTitle);
Vue.component("downloadExcel", JsonExcel);
Vue.component("kite-table", KiteTable);
Vue.component("values-table", ValuesTable);
Vue.component("fetch-values-table", FetchValuesTable);
Vue.component("kite-table-action", KiteTableAction);
Vue.component("kite-delete-button", KiteDeleteButton);
Vue.component("kite-action-button", KiteActionButton);
Vue.component("kite-edit-text", KiteEditText);

// Import the Vuex store
import store from "./store";

// Install the authentication plugin here
Vue.use(WhalePlugin, { i18n, store });

Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  // @ts-ignore
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app");

// override window alert method
window.alert = function (msg) {
  store.dispatch("alert", msg);
};
